import React, { useState } from "react";
import IconInstagram from "../../assets/images/Icon_instagram.svg";
import IconFb from "../../assets/images/icon_fb.svg";
import LogoText from "./assets/logo_text.svg";
import Logo from "./assets/logo.svg";
import {
  Box,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { variables } from "../../theme/variables";
import TabBg from "../../assets/images/tab-bg.svg";
import CloseIcon from "../../assets/images/Close.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    borderRadius: "unset",
    overflowY: "hidden",
  },
  "& .Mui-selected": {
    color: "#E35663 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#E35663",
  },
  "& .MuiTab-root": {
    color: variables.whiteColor,
    textTransform: "none",
    padding: 0,
    fontSize: "15px",
  },
  "& .policy_text": {
    overflowY: "scroll",
    maxHeight: "380px",
    maxWidth: "670px",
    padding: "0 64px",
    backgroundColor: "transparent",
    "& p": {
      fontSize: 15,
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#333142",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF526C",
      borderRadius: "8px",
    },
  },
  "& .MuiDialog-paper": {
    maxWidth: "unset",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box bgcolor={variables.success} sx={{ p: "24px 0" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Footer = ({ policyText, termsText }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpenDialog = () => {
    handleClickOpen();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = async () => {
    setOpen(true);
  };
  return (
    <Box
      bgcolor={variables.success}
      sx={{ padding: { xs: "24px 24px 32px 24px", md: "24px 80px" } }}
      className="mt-[-1px]"
    >
      <Typography
        sx={{ display: { xs: "block", md: "none" } }}
        variant="body2"
        fontSize={15}
        className="pb-4"
      >
        admin@eratu.net
      </Typography>
      <Box
        className="flex justify-between border-b-2 pb-6"
        sx={{ borderColor: variables.borderColor }}
      >
        <Box>
          <Typography
            className="cursor-pointer"
            variant="body2"
            fontSize={15}
            onClick={handleClickOpenDialog}
          >
            Terms & Privacy
          </Typography>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <Box
              sx={{
                backgroundImage: `url(${TabBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className="pt-6 pl-16 pr-6"
            >
              <Box className="flex justify-between">
                <Typography
                  className="pt-3"
                  variant="h5"
                  color={variables.whiteColor}
                >
                  We value your privacy
                </Typography>
                <Box className="cursor-pointer" onClick={handleClose}>
                  <img src={CloseIcon} alt="close" />
                </Box>
              </Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Terms and Conditions" {...a11yProps(0)} />
                <Tab label="Privacy" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div dangerouslySetInnerHTML={{ __html: termsText }}></div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div dangerouslySetInnerHTML={{ __html: policyText }}></div>
            </CustomTabPanel>
          </BootstrapDialog>
        </Box>
        <Box className="flex justify-center items-center">
          <Typography
            sx={{ display: { xs: "none", md: "block" } }}
            variant="body2"
            fontSize={15}
          >
            admin@eratu.net
          </Typography>
          <Box
            bgcolor={variables.darkIndigo}
            className="w-8 h-8 ml-[5px] mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
          >
            <IconButton href="https://www.instagram.com/eratu_app?igsh=MTR4Ym83N3N3ODdrNQ==">
              <img src={IconInstagram} alt="instagram" />
            </IconButton>
          </Box>
          <Box
            bgcolor={variables.darkIndigo}
            className="w-8 h-8 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
          >
            <IconButton href="https://www.facebook.com/share/ProP6ARLKfVY4ikL/?mibextid=qi2Omg">
              <img src={IconFb} alt="fb" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        className="mt-6 flex justify-between items-center"
      >
        <Box className="flex items-center">
          <img className="mr-2" src={Logo} alt="logo" />
          <img src={LogoText} alt="logo-text" />
        </Box>
        <Typography
          sx={{ margin: { xs: "16px 0", md: "0" } }}
          variant="subtitle2"
          fontSize={14}
        >
          © 2024 Eratu. All Rights Reserved.
        </Typography>
        <Typography variant="subtitle2" fontSize={14}>
          Macungie, Pennsylvania USA
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
